import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography, Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PurpleAccentHero from "../components/PurpleAccentHero"
import { strings } from "../utils/strings"
import CookiesSnackbar from "../components/CookiesSnackbar"
import DiscoverMoreBanner from "../components/DiscoverMoreBanner"

const useStyles = makeStyles({
    bodyHeader: {
        fontWeight: "bold",
        marginTop: 30
    },
    gridSection: {
        marginTop: 30
    },
    formBox: {
        marginLeft: 20,
        marginRight: 20
    },
    formBoxEnd: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 24
    },
    gridItemHeader: {
        textTransform: "uppercase"
    },
    headerText: {
        fontWeight: "bold"
    }
})

const TermsAndConditionsPage: FC = () => {
    const classes = useStyles()

    const data = useStaticQuery(graphql`
        query {
            desktopHeroImage: file(relativePath: { eq: "index/home-hero-desktop.jpg" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 2160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            mobileHeroImage: file(relativePath: { eq: "index/home-hero-mobile.jpg" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    const GridItemBody = ({ value }) => (
        <Typography variant="body2" align="left" color="textSecondary" paragraph={true} gutterBottom={true}>
            {value}
        </Typography>
    )

    const GridItemHeader = ({ value, anchor }) => (
        <Typography
            variant="subtitle1"
            align="left"
            color="textSecondary"
            paragraph={true}
            gutterBottom={true}
            className={classes.gridItemHeader}
            id={anchor}
        >
            {value}
        </Typography>
    )

    return (
        <Layout>
            <SEO title="Terms and Conditions" />
            <DiscoverMoreBanner/>
            <PurpleAccentHero
                desktopImage={data.desktopHeroImage.childImageSharp.fluid}
                mobileImage={data.mobileHeroImage.childImageSharp.fluid}
                alt="Man smiling in front of his computer in an open office environment"
            />
            <section>
                <Typography
                    variant="h4"
                    className={classes.bodyHeader}
                    color="textSecondary"
                    align="center"
                    gutterBottom={true}
                    paragraph={true}
                >
                    {strings?.nuliaWorksPlatformTOS}
                </Typography>

                <Grid container={true} direction="column" className={classes.gridSection} spacing={2}>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#nuliaProvisionOfPlatform" underline="none">
                            {strings?.nuliaProvisionOfPlatform}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#nuliaIntellectualProperty" underline="none">
                            {strings?.nuliaIntellectualProperty}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#yourIntellectualProperty" underline="none">
                            {strings?.yourIntellectualProperty}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#yourRightsandLicenseToNulia" underline="none">
                            {strings?.yourRightsandLicenseToNulia}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#yourCommentsAndIdeas" underline="none">
                            {strings?.yourCommentsAndIdeas}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#thirdPartyIntellectualProperty" underline="none">
                            {strings?.thirdPartyIntellectualProperty}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#permittedPlatformUses" underline="none">
                            {strings?.permittedPlatformUses}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#prohibitedPlatformUses" underline="none">
                            {strings?.prohibitedPlatformUses}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#warrantyDisclaimer" underline="none">
                            {strings?.warrantyDisclaimer}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#limitationOfLiability" underline="none">
                            {strings?.limitationOfLiability}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#indemnification" underline="none">
                            {strings?.indemnification}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#conflictTOS" underline="none">
                            {strings?.conflictTOS}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#waiver" underline="none">
                            {strings?.waiver}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#rightsAndRemedies" underline="none">
                            {strings?.rightsAndRemedies}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#severance" underline="none">
                            {strings?.severance}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#reportingAndCorrectingViolations" underline="none">
                            {strings?.reportingAndCorrectingViolations}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#governingLaw" underline="none">
                            {strings?.governingLaw}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Link href="#jurisdiction" underline="none">
                            {strings?.jurisdiction}
                        </Link>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Link href="#digitalMillenniumCopyright" underline="none">
                            {strings?.digitalMillenniumCopyright}
                        </Link>
                    </Grid>
                </Grid>

                <Grid container={true} direction="column" className={classes.gridSection} spacing={2}>
                    <Grid item={true} className={classes.formBox}>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                            gutterBottom={true}
                            paragraph={true}
                            className={classes.headerText}
                        >
                            {strings?.nuliaTermsOfUse}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                            gutterBottom={true}
                            paragraph={true}
                            className={classes.headerText}
                        >
                            {strings?.termsOfUse}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                            gutterBottom={true}
                            paragraph={true}
                        >
                            {strings?.pleaseReadTheseTermsOfUse}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                            gutterBottom={true}
                            paragraph={true}
                        >
                            {strings?.theseTermsAreAContract}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                            gutterBottom={true}
                            paragraph={true}
                        >
                            {strings?.youMustReadAgreeToAndAccept}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                            gutterBottom={true}
                            paragraph={true}
                        >
                            {strings?.subjectToTheConditions}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                            gutterBottom={true}
                            paragraph={true}
                        >
                            {strings?.youUnderstandThatByUsing}
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                            gutterBottom={true}
                            paragraph={true}
                        >
                            {strings?.ifYouAgreeToTheTOS}
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.nuliaProvisionOfPlatform1} anchor="nuliaProvisionOfPlatform" />
                        <GridItemBody value={strings?.nuliaProvisionOfPlatform1a} />
                        <GridItemBody value={strings?.nuliaProvisionOfPlatform1b} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.nuliaIntellectualProperty} anchor="nuliaIntellectualProperty" />
                        <GridItemBody value={strings?.nuliaIntellectualProperty1} />
                        <GridItemBody value={strings?.nuliaIntellectualProperty2} />
                        <GridItemBody value={strings?.nuliaIntellectualProperty3} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.yourIntellectualProperty} anchor="yourIntellectualProperty" />
                        <GridItemBody value={strings?.yourIntellectualProperty1} />
                        <GridItemBody value={strings?.yourIntellectualProperty2} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader
                            value={strings?.yourRightsandLicenseToNulia}
                            anchor="yourRightsandLicenseToNulia"
                        />
                        <GridItemBody value={strings?.yourRightsandLicenseToNulia1} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.yourCommentsAndIdeas} anchor="yourCommentsAndIdeas" />
                        <GridItemBody value={strings?.yourCommentsAndIdeas1} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader
                            value={strings?.thirdPartyIntellectualProperty}
                            anchor="thirdPartyIntellectualProperty"
                        />
                        <GridItemBody value={strings?.thirdPartyIntellectualProperty1} />
                        <GridItemBody value={strings?.thirdPartyIntellectualProperty2} />
                        <GridItemBody value={strings?.thirdPartyIntellectualProperty3} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.permittedPlatformUses} anchor="permittedPlatformUses" />
                        <GridItemBody value={strings?.permittedPlatformUses1} />
                        <GridItemBody value={strings?.permittedPlatformUses2} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.prohibitedPlatformUses} anchor="prohibitedPlatformUses" />
                        <GridItemBody value={strings?.prohibitedPlatformUses1} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2a} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2b} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2c} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2d} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2e} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2f} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2g} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2h} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2i} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2j} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2k} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2l} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2m} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2n} />
                        <GridItemBody value={strings?.prohibitedPlatformUses2o} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.warrantyDisclaimer} anchor="warrantyDisclaimer" />
                        <GridItemBody value={strings?.warrantyDisclaimer1} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.limitationOfLiability} anchor="limitationOfLiability" />
                        <GridItemBody value={strings?.limitationOfLiability1} />
                        <GridItemBody value={strings?.limitationOfLiability1a} />
                        <GridItemBody value={strings?.limitationOfLiability1b} />
                        <GridItemBody value={strings?.limitationOfLiability1c} />
                        <GridItemBody value={strings?.limitationOfLiability1d} />
                        <GridItemBody value={strings?.limitationOfLiability1e} />
                        <GridItemBody value={strings?.limitationOfLiability1f} />
                        <GridItemBody value={strings?.limitationOfLiability1g} />
                        <GridItemBody value={strings?.limitationOfLiability1h} />
                        <GridItemBody value={strings?.limitationOfLiability1i} />
                        <GridItemBody value={strings?.limitationOfLiability2} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.indemnification} anchor="indemnification" />
                        <GridItemBody value={strings?.indemnification1} />
                        <GridItemBody value={strings?.indemnification2} />
                        <GridItemBody value={strings?.indemnification3} />
                        {/*<GridItemBody value={strings?.indemnification4} />*/}
                        <GridItemBody value={strings?.indemnification5} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.conflictTOS} anchor="conflictTOS" />
                        <GridItemBody value={strings?.conflictTOS1} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.waiver} anchor="waiver" />
                        <GridItemBody value={strings?.waiver} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.rightsAndRemedies} anchor="rightsAndRemedies" />
                        <GridItemBody value={strings?.rightsAndRemedies1} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.severance} anchor="severance" />
                        <GridItemBody value={strings?.severance1} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader
                            value={strings?.reportingAndCorrectingViolations}
                            anchor="reportingAndCorrectingViolations"
                        />
                        <GridItemBody value={strings?.reportingAndCorrectingViolations1} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.governingLaw} anchor="governingLaw" />
                        <GridItemBody value={strings?.governingLaw1} />
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <GridItemHeader value={strings?.jurisdiction} anchor="jurisdiction" />
                        <GridItemBody value={strings?.jurisdiction1} />
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <GridItemHeader
                            value={strings?.digitalMillenniumCopyright}
                            anchor="digitalMillenniumCopyright"
                        />
                        <Typography variant="body2" color="textSecondary">
                            {strings?.overview}
                        </Typography>
                        <GridItemBody value={strings?.digitalMillenniumCopyright1} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright2} />
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <GridItemBody value={strings?.digitalMillenniumCopyright3} />
                        <Typography variant="body2" color="textSecondary">
                            {strings?.digitalMillenniumCopyright4}
                        </Typography>
                        <GridItemBody value={strings?.digitalMillenniumCopyright4a} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright4b} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright4c} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright4d} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright4e} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright4f} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright4g} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright5} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright6} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright7} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright8} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright9} />
                    </Grid>
                    <Grid item={true} className={classes.formBoxEnd}>
                        <GridItemBody value={strings?.digitalMillenniumCopyright10} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright10a} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright10b} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright10c} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright10d} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright10e} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright10f} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright6} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright7} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright8} />
                        <GridItemBody value={strings?.digitalMillenniumCopyright9} />
                    </Grid>
                </Grid>
            </section>
            <CookiesSnackbar />
        </Layout>
    )
}

export default TermsAndConditionsPage
